// es.js
export default {
    navbar: {
        home: "Inicio",
        browseBookList: "Navegar",
        bookRankings: "Rankings",
        aboutAuthor: "Sobre el autor",
        myLab: "Mi Biblioteca",
        lang: "Idioma",
        search: "Buscar..."
    },
    footer: {
        aboutWe: "Sobre nosotros",
        touch: "Contacto",
        socialNetwork: "Redes Sociales",
        cooperate: "Cooperación",
        facebook: "Facebook",
        termsOfService: "Términos de uso",
        facebookGroup: "Facebook grupo",
        privacyPolicy: "Políticas de privacidad",
        instagram: "Instagram",
        feedback:"Retroalimentación del problema",
        yourEmail: "Su buzón",
        feedbackContent:"Contenido",
        feedbackSuccess:"La retroalimentación fue exitosa",
        feedbackFail:"Falló la retroalimentación",
        submit: "Presentación",
        cancel: "Cancelación",
        emailNotNull: "El buzón no puede estar vacío",
        contentNotNull: "El contenido no puede estar vacío",
    },
    login: {
        title: 'Iniciar sesión',
        email: 'Email',
        emailInputPlaceholder: 'Iniciar sesión con tu correo electrónico',
        password: 'Contraseña',
        passwordInputPlaceholder: 'Introduzca su contraseña',
        password2: 'Contraseña de nuevo',
        passwordInputPlaceholder2: 'Por favor,introduzca la contraseña de nuevo',
        forgotPassword: 'Olvidé la contraseña?',
        password2Different: 'La contraseña es incoherente',
        button: 'Iniciar sesión',
        goBackLink: 'Atrás',
        emailNotBlank: '¡El buzón no puede estar vacío!',
        emailError: '¡El formato del correo electrónico es incorrecto!',
        passwordNotBlank: 'Es necesario introducir la contraseña',
        facebook: "Facebook",
        google: "Google",
        account: "¿Aún no tienes una cuenta? ",
        register: "Registrarse",
        text1: "Aceptas Nuestro",
        text2: "",
        text3: "Condiciones de Uso",
        text4: "Políticas de privacidad",
    },
    register:{
        velCode: 'Código de verificación',
        velCodePlaceholder: 'Código de verificación',
        sendVelCode: 'Enviar',
        register: 'Registro',
        sendVelCodeSuccess: "Envío exitoso"
    },
    home:{
        readCount:"leídos",
        bookUpdateTime:"Actualizados",
        moreBook:"Más",
        editorSelect:"Eleccíon del lector",
        //editorSelect:"Selección de edición",
        reputationRanking:"Más vendido",
        bestSell:"Más recomendado",
        monthly:"Recién llegado"
    },
    bookRankings:{
        rankingSelect:"Ranking",
        novelName:"Nombre del libro",
        authorName:"Nombre del autor",
        readCount:"leídos",
        readButton:"LEER",
        addToMyLib:"Añadir a mi biblioteca"
    },
    browseBookList:{
        filter:"Filtrar",
        wordCount:"Número de palabras",
        all:"Todos",
        orderByType:"Ordenar por tipo",
        popularity:"Más populares",
        recommend:"Recomendados",
        evaluate:"Evaluar",
        lastUpdate:"Actualizados",
        type:"Tipo",
        typeAll: "Todo",
        visit_rank: "Ranking de clics",
        update_rank: "Ranking de actualizaciones",
        collect_rank: "Ranking de colecciones",
        like_rank: "Ranking de Me gusta",
        read_rank: "Ranking de LEER",
        purchase_rank: "Ranking de compras",
        readCount:"LEER",
        addToMyLib:"Añadir a mi biblioteca",
        reader_choice_rank:"Elección del lector",
        more_popular_rank:"Más populales",
        new_releases_rank:"Nuevas lanzamiento",
        fantasy_rank:"Fantasía",
    },
    bookInfo:{
        bookUpdateTime: "Recién actualizado",
        commentCount: "Comentarios",
        chapterCount: "Capítulos",
        readCount: "LEER",
        addToMyLib: "Agregar a mi biblioteca",
        collected: "Recogida",
        info: "Resumen",
        subject: "Descripción",
        lastChapter: "Último Capítulo",
        chapter: "Capítulo"

    },
    topPage: {
        loginLink: 'Iniciar sesión',
        registerLink: 'Registrarse',
        logoutLink: 'Cerrar sesión'
    },
    menu: {
        index: 'Inicio',
        story: 'Historias',
        income: 'Ingresos',
        recharge: 'Recargar',
        settings: 'Ajustes',
    },
    pay: {
        one: '¡Obtén más monedas!',
        two: 'Las monedas sirven para desbloquear los capítulos',
        three: 'Formas de pago',
        btn: 'Comprar ahora por',
        done: '¿Ya has completado el pago?',
        success: 'Tu recarga se ha completado con éxito',
        fail: 'No se ha encontrado información de recarga exitosa. Puedes revisar la información de la factura más tarde',
    },
    user: {
        index: {
            unit: 'USD',
            sitUnit: 'Monedas',
            totalIncome: 'Ingreso total',
            totalReward: 'Recompensa total',
            payMsgBtn: 'Información de la cuenta',
            rechargeBtn: 'Recargar aquí',
            descBtn: 'Detalles',
            myStory: 'Mis historias',
            heatMap: 'Mapa de calor',
            dateSelect: 'Selección de fecha',
            yourPayMsg: 'Tu información de cuenta',
            emailTitle: 'Correo electrónico asociado a esta cuenta',
            emailTitleP: 'Introduce el correo electrónico asociado a esta cuenta',
            userName: 'Nombre del destinatario',
            userNameP: 'Introduce el nombre del destinatario',
            updatePayMsg: 'Confirmar',
            require: '1. Requisito del reglamento. 2. Requisito del reglamento.',
            bank: 'Banco',
            withdrawalInfo: 'Historial de retiros',
            withdrawalInfoRow: 'Retiró',
            rechargeInfo: 'Historial de recargas',
            rechargeInfoRow: 'Recargó',
            noData: 'Sin registros',
            withdrawalBtn: 'Retiró',
            confirmWithdrawalBegin: '¿Estás seguro de retirar los ingresos del mes',
            confirmWithdrawalEnd: '?',
            nickName: 'Tu nombre de usuario',
            nickNameP: 'Por favor, introduce tu nombre de usuario',
            penName: 'Seudónimo',
            sex: 'Sexo',
            male: 'Masculino',
            female: 'Femenino',
            introduce: 'Perfil',
            introduceP: 'Introduce tu perfil',
            background: 'Antecedentes',
            updUserSetting: 'Modifica tu información',
            imageType: 'Formato : jpg',
            imageSize: 'Tamaño de imagen hasta 5 MB',
            book: 'Libro',
            bankName: 'Nombre del Banco',
            bankNameP: 'Introduzca el nombre del banco',
            bankCode: 'Número de cuenta bancaria',
            bankCodeP: 'Introduzca la cuenta bancaria',
            bankUserName: 'Titular de la cuenta',
            bankUserNameP: 'Introduzca el titular de la cuenta',
            bankAddress: 'Dirección de banco',
            bankAddressP: 'Introduzca la dirección de banco',
            swiftCode: 'Código de Swift',
            swiftCodeP: 'Introduzca el código de Swift',
        },
        story: {
            published: 'Publicado',
            follow: 'Seguidores',
            infoBtn: 'Detalles',
            contract: 'Solicitar contrato',
            contractApply: 'Solicitado',
            contractSigning: 'Solicitado',
            contractSigned: 'Firmado',
            infoList: 'Lista de capítulos',
            newList: 'Nuevo capítulo',
            reads: 'LEER',
            likes: 'Me gusta',
            words: 'Palabras',
            chapter: 'Capítulo',
            infoStatus: 'Estado',
            loadingStatus: 'Cargando',
            finishedStatus: 'Finalizado',
            putMessage: 'Completa la información de tu libro antes de publicar.',
            putMessageBtn: 'Completar información',
            messagesDesc: 'Publica más palabras para que el editor comprenda mejor tu obra. Esto te ayudará a conseguir un contrato.',
            applyBookSignUpSuccess:"Presentación exitosa！",
            applyBookSignUpError:"Presentación fallida！",
            bookEdit: "Edición",
            bookEditUpdate: "Actualización",
            bookEditCancel: "Cancelación"
        },
        income: {
            name: 'Recargar Ingreso',
            appendAmount: 'Extra gratui',
            inAmount: 'Recargar',
            useAmount: 'Usar',
            bonusAmount: 'Ingreso',
            rewardAmount: 'Recompensa',
        },
        setting:{
            updateSuccess: "Actualización exitosa！"
        }
    },
    library: {
        collect: {
            title: "Mi biblioteca",
            delCollectTitle: "Eliminar de favoritos",
            addCollectTitle: "Favoritos",
        },
        readHistory: {
            title: "Historial de LEER",
            button: "Continuar",
            schedule: "Progreso",
            addCollectBtn: "Agregar",
            collectBtn: "Agregado"
        }
    },
    bookAdd: {
        avatar: "Subir portada",
        canvaLink:"Utiliza Canva",
        canvaHint:"Utiliza Canva para diseñar una portada adecuada",
        avatarFormat: "Formato de portada",
        fileFormat: "Formato de archivo",
        historicalInfo: "Información histórica",
        link:"Enlace",
        bookTitle:"Título del libro",
        bookTilePlaceholder: "El título debe tener menos de 60 caracteres",
        languageTile:"Idioma",
        audiences:"Público principal",
        gender1:"Masculino",
        gender2:"Femenino",
        contentAgeGroup:"Grupo de edad del contenido",
        contentClass:"Clasificación",
        contentClassPlaceholder:"Selecciona una clasificación",
        bookTag:"Etiquetas del libro",
        bookTagPlaceholder:"Selecciona etiquetas",
        bookDesc:"Resumen",
        bookDescPlaceholder:"Escribe una descripción para tu libro (entre 20 y 900 palabras)",
        createButton:"Crear",
        bookLanguages:"Español, Inglés",
        picUploadHint1:"¡La imagen debe ser formato JPG!",
        picUploadHint2:"El tamaño de la imagen no debe superar los 5MB",
        bookAddHint1:"El título del libro no puede estar vacío",
        bookAddHint2:"La portada del libro no puede estar vacía",
        bookAddHint3:"La descripción del libro no puede estar vacía",
        addBookSuccess: "La historia se agregó con éxito, ve a agregar el capítulo.",
        success: "éxito",
        cancel: "Cancelación",
        penNameNotNull: "El seudónimo no puede estar vacío",
        registerSuccess: "Registro exitoso",
        registerFail: "Falló el registro",
        registerAuthor: "Registrarse como escritor",
        register: "Registro",
        penName: "Seudónimo",
        yourPenName: "Tu seudónimo"
    },
    bookList: {
        title: "Novel List",
        title1: "Title",
        title2: "Category",
        title3: "Click Count",
        title4: "Yesterday's Subscriptions",
        title5: "Update Time",
        title6: "Total Words",
        title7: "Action",
        btn: "Publish Novel",
        btn1: "Chapter Management",
        hint1: "Update",
        hint2: "Nothing here - create a book now!",
    },
    chapterAdd: {
        addBtn:"Lista de capítulos",
        search:"Buscar",
        titlePlaceholder:"Introduce el título",
        vipRadio1:"De pago",
        vipRadio2:"Gratis",
        pricePlaceholder:"Introduce el precio del capítulo",
        draftFontCount:"Palabras",
        draftBtn:"Guardar en borrador",
        createBtn:"Crear",
        viewBtn:"Vista previa",
        hint1:"El nombre del capítulo no puede estar vacío",
        hint2:"El contenido del capítulo no puede estar vacío",
        hint3:"El contenido del capítulo es demasiado corto",
        hint4:"Actualización exitosa.",
        hint5: "Publicar borrador",
    },
    chapterPreview:{
        goBack: "Volver"
    },
    chapterDrafts: {
        link1: "Gestión de Novelas",
        link2: "Capítulos Publicados",
        title: "Bandeja de Borradores",
        title1: "Nombre del Capítulo",
        title2: "Tiempo de Actualización",
        title3: "Acción",
        hint1: "Actualizar",
        hint2: "Nada aquí, ¡crea un capítulo ahora!",
        btn1: "Editar",
        btn2: "Eliminar",
    },
    chapterList: {
        link1: "Gestión de Novelas",
        link2: "Buzón de Borradores",
        title: "Lista de Capítulos",
        title1: "Nombre del Capítulo",
        title2: "Tiempo de Actualización",
        title3: "¿Es de Pago?",
        title4: "Acción",
        hint1: "Actualizar",
        hint2: "Nada aquí, ¡crea un capítulo ahora!",
        hint3: "Pago",
        hint4: "Gratis",
        btn: "Nuevo Capítulo",
        btn1: "Editar",
        btn2: "Eliminar",
        btn3: "¿¿ está seguro de eliminar?",
        btn4: "Eliminación exitosa!",
    },
    readPage: {
        preChapterBtn:"Capítulo anterior",
        newChapterBtn:"Próximo capítulo",
        chapterAbout:"Capítulos relacionados",
        shareTo: "Compartir",
        unLock: "Desbloquear",
        unlockPrice: "Desbloqueado inmediatamente"
    },
    feadBack: {
        title:"Feedback",
        questionType:"Selecciona el tipo de pregunta",
        questionPlaceholder:"Selecciona el tipo de pregunta",
        email:"Dirección de correo electrónico",
        emailPlaceholder:"Ingresa tu dirección de correo electrónico aquí",
        content:"Deja tus sugerencias aquí",
        contentPlaceholder:"Deja tus sugerencias aquí",
        picTitle:"Sube una captura de pantalla para que podamos entender mejor tus comentarios",
        button:"Enviar",
        hint1:"La imagen debe ser formato JPG",
        hint2:"El tamaño de la imagen no debe superar los 5MB",
        hint3:"La dirección de correo electrónico no puede estar vacía",
        hint4:"La pregunta no puede estar vacía",
        hint5:"El contenido del feedback no puede estar vacío",
        hint6:"El contenido del feedback debe tener al menos 10 caracteres",
        hint7:"¡Envío exitoso!",
    },
    aboutAuthor: {
        row1: "¡Ser escritor en Minovel!",
        row2: "Dedicado a crear para todos los lectores del mundo a los que les gusta leer novelas,",
        row3: "¡Bienvenidos todos los que tienen el sueño de escribir!",
        row4: "Ser escritor",
        row6: "Un equipo editorial profesional para ofrecerle un servicio personalizado de orientación sobre contenidos.",
        row8: "Equipo maduro de operaciones y marketing para promocionar y publicar su libro.",
        row10: "Organizar periódicamente diversas actividades de redacción para estimular la creatividad de los autores.",
        row19: "La voz del autor:",
        row20: "1. Consejos de escritura: como escritor de novelas por internet, ¡me encantaría decir que debemos estar agradecidos a nuestros lectores! Después de escribir novelas durante tantos años, he resumido muchos consejos de escritura. Quizá todos los escritores de novelas en red sientan que cada paso que dan es su propio trabajo duro. Y lo que me gustaría decir aún más es que nosotros, los escritores, no podemos sustraernos al apoyo de los lectores en cada paso de nuestro éxito.",
        row33: "2. En mi opinión, hay tres grandes categorías de escritores. Los escritores principiantes sólo escriben historias; los escritores intermedios son capaces de formarse un punto de vista propio; y los escritores avanzados son capaces no sólo de destilar algo que quieren expresar de una historia, sino también de reflejar la realidad. Mi proceso creativo también se divide en tres etapas. La primera es la etapa de creación instintiva, es decir, crear por impulso emocional, lleno de emoción pero sin mucha habilidad para escribir. La segunda etapa es el cuello de botella creativo. En esta etapa, lo que hago es leer mucho, aprender de memoria, practicar más, copiar el texto completo de la fundación, para comprender las habilidades de escritura, de modo que la obra se presente más sofisticada. La tercera etapa es tratar de participar en el trabajo de pensar y sentir, para que el texto pueda expresar algo más que el texto. Por ejemplo, utilizar el poder espiritual incrustado en la historia para contagiar a los lectores, de modo que éstos se llenen del poder de querer ir hacia arriba. Me he dado cuenta profundamente de que la creatividad debe basarse en la realidad, y el pie debe estar en toda la pantalla.",
        row23: "Círculo de autores:",
        row27: "1. Sigue las cuentas sociales y sigue a los editores para aprender más sobre escritura. Inspírate más con otros grandes autores de todo el mundo. Crea un buen ambiente para la creatividad y el intercambio cultural.",
        row29: "2. Consigue más recompensas mientras aprendes, y deja que la escritura alcance una vida mejor.",
        row31: "3. Promociona y da a conocer tu obra en las redes sociales, para que más gente la entienda y la lea, y se convierta en tus fieles lectores.",
    },
};
