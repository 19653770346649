import {createRouter, createWebHashHistory} from 'vue-router'

const router = createRouter({
    // createWebHistory 路由模式路径不带#号(生产环境下不能直接访问项目，需要 nginx 转发)
    // createWebHashHistory 路由模式路径带#号
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/home',
            name: 'home',
            redirect: '/home/page',
            component: () => import('@/views/NovelHome'),
            children: [
                {
                    path: 'page',
                    name: 'homePage',
                    component: () => import('@/views/Home')
                },
                {
                    path: 'homeOld',
                    name: 'homeOld',
                    component: () => import('@/views/HomeOld')
                },
                {
                    path: 'browseBookList',
                    name: 'browseBookList',
                    component: () => import('@/views/book/BrowseBookList')
                },
                {
                    path: 'aboutAuthor',
                    name: 'aboutAuthor',
                    component: () => import('@/views/AboutAuthor')
                },
                {
                    path: 'bookRankings',
                    name: 'bookRankings',
                    component: () => import('@/views/book/BookRankings')
                },
                {
                    path: 'bookInfo',
                    name: 'bookInfo',
                    component: () => import('@/views/book/BookInfo')
                },
                {
                    path: 'library',
                    name: 'library',
                    component: () => import('@/views/library/LibraryIndex')
                },
                {
                    path: 'readPage',
                    name: 'readPage',
                    component: () => import('@/views/readPage')
                },

            ]
        },
        {
            path: '/register',
            name: 'register',
            component: () => import('@/views/Register')
        },
        {
            path: '/emailRegister',
            name: 'emailRegister',
            component: () => import('@/views/EmailRegister')
        },
        {
            path: '/loginNew',
            name: 'loginNew',
            component: () => import('@/views/LoginNew')
        },
        {
            path: '/emailLogin',
            name: 'emailLogin',
            component: () => import('@/views/EmailLogin')
        },
        {
            path: '/forgotPassword',
            name: 'forgotPassword',
            component: () => import('@/views/ForgotPassword')
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login')
        },
        {
            path: '/privacyPolicy',
            name: 'privacyPolicy',
            component: () => import('@/views/privacyPolicy/PrivacyPolicy')
        },
        {
            path: '/aboutWe',
            name: 'aboutWe',
            component: () => import('@/views/aboutWe/AboutWe')
        },
        {
            path: '/termsOfService',
            name: 'termsOfService',
            component: () => import('@/views/termsOfService/TermsOfService')
        },
        {
            path: '/feadback',
            name: 'feadback',
            component: () => import('@/views/FeadBack')
        },
        {
            path: '/news/:id',
            name: 'news',
            component: () => import('@/views/News')
        },
        {
            path: '/bookclass',
            name: 'bookclass',
            component: () => import('@/views/BookClass')
        },
        {
            path: '/book_rank',
            name: 'bookRank',
            component: () => import('@/views/BookRank')
        },
        {
            path: '/book/:id',
            name: 'book',
            component: () => import('@/views/Book')

        },
        {
            path: '/chapter_list/:bookId',
            name: 'chapterList',
            component: () => import('@/views/ChapterList')

        },
        {
            path: '/book/:id/:chapterId',
            name: 'bookContent',
            component: () => import('@/views/BookContent')

        },
        {
            path: '/user/setup',
            name: 'userSetup',
            component: () => import('@/views/UserSetup')
        },
        {
            path: '/user/comment',
            name: 'userComment',
            component: () => import('@/views/UserComment')
        },
        {
            path: '/user/index',
            name: 'userIndex',
            component: () => import('@/views/UserIndex'),
            children: [
                {
                    path: '/user/index/sub',
                    name: 'userSubIndex',
                    component: () => import('@/views/index/SubIndex')
                },
                {
                    path: '/user/index/withdrawal',
                    name: 'userSubWithdrawal',
                    component: () => import('@/views/index/Withdrawal')
                },
                {
                    path: '/user/index/rechargeInfo',
                    name: 'userSubRechargeInfo',
                    component: () => import('@/views/index/RechargeInfo')
                },
                {
                    path: '/user/index/withdrawalInfo',
                    name: 'userSubWithdrawalInfo',
                    component: () => import('@/views/index/WithdrawalInfo')
                },
            ]
        },
        {
            path: '/user/story',
            name: 'userStory',
            component: () => import('@/views/UserStory'),
            children: [
                {
                    path: '/user/story/sub',
                    name: 'userSubStory',
                    component: () => import('@/views/story/SubStory')
                },
                {
                    path: '/user/story/info/:bookId',
                    name: 'userStoryInfo',
                    component: () => import('@/views/story/StoryInfo')
                },
            ]
        },
        {
            path: '/user/income',
            name: 'userIncome',
            component: () => import('@/views/UserIncome'),
            children: [
                {
                    path: '/user/income/sub',
                    name: 'userSubIncome',
                    component: () => import('@/views/income/SubIncome')
                }
            ]
        }
        , {
            path: '/user/recharge',
            name: 'userRecharge',
            component: () => import('@/views/UserRecharge')
        }
        , {
            path: '/user/setting',
            name: 'userSetting',
            component: () => import('@/views/UserSetting')
        },
        {
            path: '/author/user/show',
            name: 'userShow',
            component: () => import('@/views/UserShow')
        },
        {
            path: '/author/register',
            name: 'authorRegister',
            component: () => import('@/views/author/Register')
        },
        {
            path: '/author/book_list',
            name: 'authorBookList',
            component: () => import('@/views/author/BookList')
        },
        {
            path: '/author/book_add',
            name: 'authorBookAdd',
            component: () => import('@/views/author/BookAddNew')
        },

    {
      path: '/author/chapter_list',
      name: 'authorChapterList',
      component: () => import('@/views/author/ChapterList')
    },
    {
        path: '/author/chapter_drafts',
        name: 'authorChapterDrafts',
        component: () => import('@/views/author/ChapterDrafts')
    },
    {
      path: '/author/chapter_add',
      name: 'authorChapterAdd',
      component: () => import('@/views/author/ChapterAddNew')
    },
    {
        path: '/author/chapterEdit',
        name: 'authorChapterEdit',
        component: () => import('@/views/author/ChapterEdit.vue')
    },
    {
      path: '/author/chapter_preview',
      name: 'authorChapterPreview',
      component: () => import('@/views/readPage/Preview')
    },
    {
      path: '/author/chapter_update',
      name: 'authorChapterUpdate',
      component: () => import('@/views/author/ChapterUpdate')
    },
    {
      path: '/author/viewAuthorHome/:id',
      name: 'viewAuthorHome',
      component: () => import('@/views/author/ViewAuthorHome')
    }
  ]
})

router.beforeEach(async (to, from) => {
    console.log("from:"+from.name)
    console.log("to:"+to.name)
    if (from.name === to.name) {
        return false;
    }
    return true;
})

// 解决 vue 中路由跳转时，总是从新页面中间开始显示
router.afterEach((to,from,next) => {
  window.scrollTo(0,0)
})

export default router
