import { createI18n } from 'vue-i18n'
import zh_cn from "./zh_cn"
import en_us from "./en_us"
//import es from "./es";
import es_es from "./es_es";
import {getLang} from "@/utils/lang";
  // 全局注册国际化包

// 准备翻译的语言环境信息
const i18n = createI18n({
    legacy: false, // VUE3 组合式API
    locale: getLang(), // 默认cn语言环境
    fallbackLocale: 'es_es',  //备用语言环境
    messages:{
        "zh_cn":zh_cn,
        "en_us":en_us,
        "es_es":es_es,
    }
})

export default i18n
