// zh_cn.js
export default {
    navbar: {
        home: "首页",
        browseBookList: "浏览",
        bookRankings: "排行",
        aboutAuthor: "对于作者",
        myLab: "我的图书馆",
        lang: "语言",
        search: "寻找..."
    },
    footer: {
        aboutWe: "关于我们",
        touch: "接触",
        socialNetwork: "社交网络",
        cooperate: "合作",
        facebook: "Facebook",
        termsOfService: "使用条款",
        facebookGroup: "脸书群组",
        privacyPolicy: "隐私政策",
        instagram: "Instagram",
        feedback:"问题反馈",
        yourEmail: "您的邮箱",
        feedbackContent:"反馈内容",
        feedbackSuccess:"反馈成功",
        feedbackFail:"反馈失败",
        submit: "提交",
        cancel: "取消",
        emailNotNull: "邮箱不能为空",
        contentNotNull: "内容不能为空",
    },
    login: {
        title: '登录',
        email: '邮箱',
        emailInputPlaceholder: '请输入邮箱',
        password: '密码',
        passwordInputPlaceholder: '请输入密码',
        password2: '再次密码',
        passwordInputPlaceholder2: '请再次输入密码',
        forgotPassword: '忘记密码？',
        password2Different: '两次密码不一致',
        button: '登录',
        goBackLink: '返回',
        emailNotBlank: '邮箱不能为空！',
        emailError: '邮箱格式不正确！',
        passwordNotBlank: '密码不能为空！',
        facebook: "Facebook",
        google: "Google",
        account: "您还没有账户吗？",
        register: "点击注册",
        text1: "继续，即表示您同意",
        text2: "我们的",
        text3: "使用条件",
        text4: "隐私政策",
    },
    register:{
        velCode: '验证码',
        velCodePlaceholder: '请输入验证码',
        sendVelCode:'发送验证码',
        register: '注册',
        sendVelCodeSuccess: "发送成功"
    },
    home: {
        readCount: "阅读",
        bookUpdateTime: "刚刚更新",
        moreBook: "更多的",
        editorSelect: "读者的选择",
        reputationRanking: "最畅销",
        bestSell: "最推荐",
        monthly: "最近更新"
    },
    bookRankings: {
        rankingSelect: "排行选择",
        novelName: "书籍名称",
        authorName: "作者名称",
        readCount: "阅读",
        addToMyLib: "添加到我的图书馆",
        readButton: "阅读"
    },
    browseBookList: {
        filter: "过滤",
        wordCount: "字数",
        all: "全部",
        orderByType: "排序方式",
        popularity: "人气",
        recommend: "推荐",
        evaluate: "评价",
        lastUpdate: "最近更新",
        type: "种类",
        typeAll: "全部",
        visit_rank: "点击榜",
        update_rank: "更新榜",
        collect_rank: "收藏榜",
        like_rank: "点赞榜",
        read_rank: "阅读榜",
        purchase_rank: "购买榜",
        readCount: "阅读",
        addToMyLib: "添加到我的图书馆",
        reader_choice_rank:"读者选择",
        more_popular_rank:"最受欢迎",
        new_releases_rank:"新晋书籍",
        fantasy_rank:"玄幻小说",
    },
    bookInfo: {
        bookUpdateTime: "刚刚更新",
        commentCount: "评论",
        chapterCount: "章节",
        readCount: "阅读",
        addToMyLib: "添加到我的图书馆",
        collected: "已收藏",
        info: "概括",
        subject: "概要",
        lastChapter: "最新章节",
        chapter: "章"
    },
    topPage: {
        loginLink: '登录',
        registerLink: '注册',
        logoutLink: '退出'
    },
    menu: {
        index: '主页',
        story: '故事',
        income: '收入',
        recharge: '充值',
        settings: '设置',
    },
    pay: {
        one: '获得更多积分',
        two: '积分用于解锁付费章节',
        three: '支付方式',
        btn: '立即购买，价格为',
        done: '已经支付完成？',
        success: '您已经充值成功',
        fail: '暂未查询到充值成功，稍后可关注账单信息',
    },
    user: {
        index: {
            unit: '美元',
            sitUnit: '金币',
            totalIncome: '累计收入',
            totalReward: '奖赏',
            payMsgBtn: '账户信息',
            rechargeBtn: '在这里充值',
            descBtn: '细节',
            myStory: '我的故事',
            heatMap: '热力图',
            dateSelect: '时间选择',
            yourPayMsg: '您的账户信息',
            emailTitle: '与此账户关联的电子邮件',
            emailTitleP: '请输入与此账户关联的电子邮件',
            userName: '收件人姓名',
            userNameP: '请输入收件人姓名',
            updatePayMsg: '修改信息',
            require: '1.条例规定条例规定条例规定条例规定条例,2.条例规定条例规定条例规定条例规定条例',
            bank: 'bank',
            withdrawalInfo: '提现记录',
            withdrawalInfoRow: '提现了',
            rechargeInfo: '充值记录',
            rechargeInfoRow: '充值了',
            noData: '暂无记录',
            withdrawalBtn: '提现',
            confirmWithdrawalBegin: '确定提现',
            confirmWithdrawalEnd: '的收入吗？',
            nickName: '你的昵称',
            nickNameP: '请输入昵称',
            penName: '你的笔名',
            sex: '你的性别',
            male: '男',
            female: '女',
            introduce: '简    介',
            introduceP: '请输入简介',
            background: '背景图',
            updUserSetting: '修改你的信息',
            imageType: '必须上传 JPG 格式的图片!',
            imageSize: '图片大小最多 5MB!',
            book: '书籍',
            bankName: '银行名称',
            bankNameP: '请输入银行名称',
            bankCode: '银行账号',
            bankCodeP: '请输入银行账号',
            bankUserName: '账户人名称',
            bankUserNameP: '请输入账户人名称',
            bankAddress: '银行地址',
            bankAddressP: '请输入银行地址',
            swiftCode: '银行地址',
            swiftCodeP: '请输入银行代码',
        },
        story: {
            published: '购买',
            follow: '订阅者',
            infoBtn: '详情',
            contract: '申请签约',
            contractApply: '已申请',
            contractSigning: '签约中',
            contractSigned: '已签约',
            infoList: '章节列表',
            newList: '新章节',
            reads: '阅读',
            likes: '我喜欢',
            words: '字数',
            chapter: '章节',
            infoStatus: '状态',
            loadingStatus: '连载中',
            finishedStatus: '已完结',
            putMessage: '在出版之前填写您的书籍的信息。',
            putMessageBtn: '填写信息',
            messagesDesc: '发表更多字数，让编辑更好地了解你的作品，这将有利于你获得合同。',
            applyBookSignUpSuccess:"提交成功！",
            applyBookSignUpError:"提交失败！",
            bookEdit: "编辑",
            bookEditUpdate: "更新",
            bookEditCancel: "取消"
        },
        income: {
            name: '充值收入图',
            appendAmount: '月充值赠送',
            inAmount: '月充值',
            useAmount: '月使用',
            bonusAmount: '月收入',
            rewardAmount: '月打赏',
        },
        setting:{
            updateSuccess: "更新成功！"
        }
    },
    library: {
        collect: {
            title: "我的图书馆",
            delCollectTitle: "取消收藏",
            addCollectTitle: "收藏",
        },
        readHistory: {
            title: "阅读记录",
            button: "继续",
            schedule: "进度",
            addCollectBtn: "添加",
            collectBtn: "已添加"
        }
    },
    bookAdd: {
        avatar: "上传封面",
        canvaLink:"使用 Canva",
        canvaHint:"使用 Canva 设计一个合适的封面",
        avatarFormat: "封面格式",
        fileFormat: "文件格式",
        historicalInfo: "历史信息",
        link: "跳转",
        bookTitle: "书名",
        bookTilePlaceholder: "书名限制60个字符",
        languageTile: "语言",
        audiences: "主要受众",
        gender1: "男性",
        gender2: "女性",
        contentAgeGroup: "内容年龄段",
        contentClass: "分类",
        contentClassPlaceholder: "选择分类",
        bookTag: "小说标签",
        bookTagPlaceholder: "选择标签",
        bookDesc: "概要",
        bookDescPlaceholder: "为你的书籍写一个描述（20～900字之间）",
        createButton: "创造",
        bookLanguages: "西班牙语,英语",
        picUploadHint1: "必须上传 JPG/PNG 格式的图片!",
        picUploadHint2: "图片大小最多 1MB!",
        bookAddHint1: "书名不能为空",
        bookAddHint2: "封面不能为空",
        bookAddHint3: "简介不能为空",
        addBookSuccess: "故事添加成功，快去添加章节吧",
        success: "成功",
        cancel: "取消",
        penNameNotNull: "笔名不能为空",
        registerSuccess: "注册成功",
        registerFail: "注册失败",
        registerAuthor: "注册成为作家",
        register: "注册",
        penName: "笔名",
        yourPenName: "你的笔名"
    },
    bookList: {
        title:"小说列表",
        title1:"书名",
        title2:"分类",
        title3:"点击量",
        title4:"昨日订阅数",
        title5:"更新时间",
        title6:"总字数",
        title7:"操作",
        btn:"新的故事",
        btn1:"章节管理",
        hint1:"更新",
        hint2:"啥都没有，快去创建书籍吧"
    },
    chapterAdd: {
        addBtn: " 章节列表",
        search: "搜索",
        titlePlaceholder: "请输入标题",
        vipRadio1: "收费",
        vipRadio2: "免费",
        pricePlaceholder: "请输入章节价格",
        draftFontCount: "字",
        draftBtn: "存入草稿",
        createBtn: "创造",
        viewBtn: "预览",
        hint1: "章节名不能为空!",
        hint2: "章节内容不能为空!",
        hint3: "章节内容太少!",
        hint4: "更新成功!",
        hint5: "发布草稿"
    },
    chapterPreview:{
        goBack: "返回"
    },
    chapterDrafts: {
        link1:"我的故事",
        link2:"已发布章节",
        title:"草稿箱",
        title1:"章节名",
        title2:"更新时间",
        title3:"操作",
        hint1:"更新",
        hint2:"啥都没有～快去创建章节吧",
        btn1:"编辑",
        btn2:"删除",
    },
    chapterList: {
        link1:"我的故事",
        link2:"草稿箱",
        title:"章节列表",
        title1:"章节名",
        title2:"更新时间",
        title3:"是否收费",
        title4:"操作",
        hint1:"更新",
        hint2:"啥都没有～ 快去创建章节吧",
        hint3:"收费",
        hint4:"免费",
        btn:"新建章节",
        btn1:"修改",
        btn2:"删除",
        btn3: "确定删除吗？",
        btn4: "删除成功！",
    },
    readPage: {
        preChapterBtn: "上一章",
        newChapterBtn: "下一章",
        chapterAbout: "相关章节",
        shareTo: "分享",
        unLock: "解锁",
        unlockPrice: "马上解锁"
    },
    feadBack: {
        title: "反馈",
        questionType: "选择问题类型",
        questionPlaceholder: "请选择问题类型",
        email: "邮箱地址",
        emailPlaceholder: "请在此处填写您的邮箱地址",
        content: "请在此处留下你的建议",
        contentPlaceholder: "请在此处留下你的建议",
        picTitle: "请上传截图以便我们更好的了解您的想法",
        button: "提交",
        hint1: "必须上传 JPG 格式的图片!",
        hint2: "图片大小最多 5MB!",
        hint3: "邮箱不能为空!",
        hint4: "反馈问题不能为空!",
        hint5: "反馈内容不能为空!",
        hint6: "反馈内容不能少于 10 个字符!",
        hint7: "提交成功!",
    },
    aboutAuthor: {
        row1: "成为 MINOVEL 的作家！",
        row2: "MINOVEL 是许多西半夜语国家和地区的顶级图书列表应用程序之一，",
        row3: "拥有数百万各个年龄段和性别的读书",
        row4: "成为一名作家",
        row5: "作者永远是第一位的",
        row6: "为所有人提供有关写作、营销的及时回复和专业建议。",
        row7: "作者永远是第一位的。",
        row8: "为所有人提供有关写作、营销的及时回复和专业建议。",
        row9: "作者永远是第一位的。",
        row10: "为所有人提供有关写作、营销的及时回复和专业建议。",
        row11: "被发现",
        row12: "7,168,839 名读者并且还在不断增长",
        row13: "91%阅读优质故事并为内容付费",
        row14: "社交媒体每月广告费用超过 70 万美元",
        row15: "7,168,839 名读者并且还在不断增长",
        row16: "91%阅读优质故事并为内容付费",
        row17: "社交媒体每月广告费用超过 70 万美元",
        row18: "得到报酬",
        row19: "作者的声音",
        row20: "作者的想法作者的想法作者的想法作者的想法作者的想法作者的想法作者的想法作者的想法作者的想法作者的想法",
        row21: "了解更多",
        row22: "作者名称",
        row23: "作者课堂",
        row24: "作者课堂的目标是为作者提供一个",
        row25: "写作支持平台，其中包括：",
        row26: "实用写作教程。",
        row27: "我们的编辑团队提供在线课程，包括录制视频和直播课程，指导作家提高写作水平。通过课程，他们不仅可以学习最流行的现代写作技巧，还可以提高技能，增强才华。",
        row28: "对您的作品进行个人指导和辅导。",
        row29: "我们为潜在作者提供个性化评论，帮助他们创作流行且成功的书籍。实现您的个人和职业成长。",
        row30: "友好且支持的作者圈子。",
        row31: "最重要的是，享受写作。我们的作者课堂群组是一个充满爱心的社区，您可以在这里分享经验、日常事务、新想法并与其他作者互动。以及能够以视频格式访问作者课堂最受欢迎的作家分享的不同经历。",
    },
};
