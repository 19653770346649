<template>
  <div id="app" class="app">
    <router-view />
    <el-backtop :right="100" :bottom="100" />
  </div>
</template>

<script>
export default  {
  name:  'App'
}
</script>
<style scoped>
.app{
  width: 100%;
  margin: 0 auto;
}
</style>
