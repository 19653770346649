const LangKey = 'lang'
const SupportLangKey = 'supportLang'

export const getLang = () => {
    //localStorage.setItem(LangKey, 'zh_cn')
    let lk = localStorage.getItem(LangKey)
    if (!lk || lk === 'zh_cn') {
        localStorage.setItem(LangKey, 'es_es')
        return 'es_es';
    }
    return localStorage.getItem(LangKey);
}

export const setLang = (lang) => {
    return localStorage.setItem(LangKey, lang)
}

export const getSupportLang = () => {
    if (!localStorage.getItem(SupportLangKey)) {
        localStorage.setItem(SupportLangKey, JSON.stringify(["es_es","en_us"]))
        return ["es_es","en_us"];
    }
    return JSON.parse(localStorage.getItem(SupportLangKey));
}

export const setSupportLang = (lang) => {
    return localStorage.setItem(SupportLangKey, JSON.stringify(lang))
}
