// en_us.js
export default {
    navbar: {
        home: "Home",
        browseBookList: "Browse",
        bookRankings: "Rankings",
        aboutAuthor: "About the Author",
        myLab: "My Library",
        lang: "Language",
        search: "Find..."
    },
    footer: {
        aboutWe: "About We",
        touch: "Touch",
        socialNetwork: "Social Network",
        cooperate: "Cooperate",
        facebook: "Facebook",
        termsOfService: "Terms Of Service",
        facebookGroup: "Facebook Group",
        privacyPolicy: "Privacy Policy",
        instagram: "Instagram",
        feedback:"Problem feedback",
        yourEmail: "Your Email",
        feedbackContent:"Feedback Content",
        feedbackSuccess:"Feedback Success",
        feedbackFail:"Feedback Fail",
        submit: "Submit",
        cancel: "Cancel",
        emailNotNull: "Email Not Null",
        contentNotNull: "Content Not Null",
    },
    login: {
        title: 'Login',
        email: 'Email',
        emailInputPlaceholder: 'Please enter your email',
        password: 'Password',
        passwordInputPlaceholder: 'Please enter your password',
        password2: 'Password again',
        passwordInputPlaceholder2: 'Please enter the password again',
        forgotPassword: 'I forgot the password?',
        password2Different: 'The password is inconsistency',
        button: 'Login',
        goBackLink: 'Go back',
        emailNotBlank: 'Email cannot be blank!',
        emailError: 'Invalid email format!',
        passwordNotBlank: 'Password cannot be blank!',
        facebook: "Facebook",
        google: "Google",
        account: "Have an account? ",
        register: "register",
        text1: "Agree We",
        text2: "",
        text3: "Conditions of Use",
        text4: "Privacy Policy",
    },
    register:{
        velCode: 'Verification code',
        velCodePlaceholder: 'Enter verification code',
        sendVelCode: 'Send verification code',
        register: 'Register',
        sendVelCodeSuccess: "Send Success"
    },
    home: {
        readCount: "Read",
        bookUpdateTime: "Just updated",
        moreBook: "More",
        editorSelect: "Reader's choice",
        //editorSelect:"Edit Selection",
        reputationRanking: "Best selling",
        bestSell: "Most recommended",
        monthly: "New arrival"
    },
    bookRankings: {
        rankingSelect: "Ranking Select",
        novelName: "Book Name",
        authorName: "Author Name",
        readCount: "Read",
        addToMyLib: "Add to My Library",
        readButton: "Read"
    },
    browseBookList: {
        filter: "Filter",
        wordCount: "Word Count",
        all: "All",
        orderByType: "Order by",
        popularity: "Popularity",
        recommend: "Recommend",
        evaluate: "Evaluate",
        lastUpdate: "Last Update",
        type: "Type",
        typeAll: "All",
        visit_rank: "Click Ranking",
        update_rank: "Update Ranking",
        collect_rank: "Collection Ranking",
        like_rank: "Like Ranking",
        read_rank: "Reading Ranking",
        purchase_rank: "Purchase Ranking",
        readCount: "Read",
        addToMyLib: "Add to My Library",
        reader_choice_rank:"Reader's choice",
        more_popular_rank:"More popular",
        new_releases_rank:"New releases",
        fantasy_rank:"Fantasy",
    },
    bookInfo: {
        bookUpdateTime: "Just updated",
        commentCount: "Comments",
        chapterCount: "Chapters",
        readCount: "Reads",
        addToMyLib: "Add to My Library",
        collected: "Favorite",
        info: "Summary",
        subject: "Overview",
        lastChapter: "Latest Chapter",
        chapter: "Chapter"
    },
    topPage: {
        loginLink: 'Login',
        registerLink: 'Register',
        logoutLink: 'Logout'
    },
    menu: {
        index: 'Home',
        story: 'Story',
        income: 'Income',
        recharge: 'Recharge',
        settings: 'Settings',
    },
    pay: {
        one: 'Get more points',
        two: 'Coins are used to unlock chapters',
        three: 'Payment method',
        btn: 'Buy now, price is',
        done: 'Payment completed?',
        success: 'You have successfully recharged',
        fail: 'No successful recharge found, please check the billing information later',
    },
    user: {
        index: {
            unit: 'USD',
            sitUnit: 'Gold Coins',
            totalIncome: 'Total Income',
            totalReward: 'Rewards',
            payMsgBtn: 'Account Information',
            rechargeBtn: 'Recharge here',
            descBtn: 'Details',
            myStory: 'My Story',
            heatMap: 'Heat Map',
            dateSelect: 'Date Select',
            yourPayMsg: 'Your Account Information',
            emailTitle: 'Email associated with this account',
            emailTitleP: 'Please enter the email associated with this account',
            userName: 'Recipient Name',
            userNameP: 'Please enter the recipient name',
            updatePayMsg: 'Confirm',
            require: '1. Regulations Regulations Regulations Regulations Regulations, 2. Regulations Regulations Regulations Regulations Regulations',
            bank: 'bank',
            withdrawalInfo: 'Withdrawal Records',
            withdrawalInfoRow: 'Withdrawn',
            rechargeInfo: 'Recharge Records',
            rechargeInfoRow: 'Recharged',
            noData: 'No records found',
            withdrawalBtn: 'Withdrawn',
            confirmWithdrawalBegin: 'Are you sure you want to withdraw your income for',
            confirmWithdrawalEnd: '?',
            nickName: 'Your username',
            nickNameP: 'Please enter your username',
            penName: 'Pen Name',
            sex: 'Sex',
            male: 'Male',
            female: 'Female',
            introduce: 'brief introduction',
            introduceP: 'Introduce your personal information',
            background: 'Background',
            updUserSetting: 'Modify your information',
            imageType: 'Format: jpg',
            imageSize: 'Image size up to 5 MB',
            book: 'Book',
            bankName: 'Name of the bank',
            bankNameP: 'Enter the name of the bank',
            bankCode: 'Bank account number',
            bankCodeP: 'Enter the bank account number',
            bankUserName: 'Account holder\'s name',
            bankUserNameP: 'Enter the account holder\'s name',
            bankAddress: 'Bank address',
            bankAddressP: 'Enter the bank address',
            swiftCode: 'Swift code',
            swiftCodeP: 'Enter the Swift code',
        },
        story: {
            published: 'Published',
            follow: 'Subscribers',
            infoBtn: 'Details',
            contract: 'Apply for Contract',
            contractApply: 'Contract Applied',
            contractSigning: 'Contract Signing',
            contractSigned: 'Contract Signed',
            infoList: 'Chapter List',
            newList: 'New Chapter',
            reads: 'Reads',
            likes: 'Likes',
            words: 'Words',
            chapter: 'Chapter',
            infoStatus: 'Status',
            loadingStatus: 'Loading',
            finishedStatus: 'Finished',
            putMessage: 'Fill in the information about your book before publishing.',
            putMessageBtn: 'Fill in Information',
            messagesDesc: 'Publish more words to let the editors better understand your work, which will help you get a contract.',
            applyBookSignUpSuccess:"Apply Success",
            applyBookSignUpError:"Apply Fail",
            bookEdit: "Edit",
            bookEditUpdate: "Update",
            bookEditCancel: "Cancel"
        },
        income: {
            name: 'Recharge Income Map',
            appendAmount: 'Free extra',
            inAmount: 'Recharge',
            useAmount: 'Use',
            bonusAmount: 'Income',
            rewardAmount: 'Rewards',
        },
        setting:{
            updateSuccess: "Update Success！"
        }
    },
    library: {
        collect: {
            title: "My Library",
            delCollectTitle: "Remove",
            addCollectTitle: "Add",
        },
        readHistory: {
            title: "Reading History",
            button: "Continue",
            schedule: "Progress",
            addCollectBtn: "Add",
            collectBtn: "Added"
        }
    },
    bookAdd: {
        avatar: "Upload Cover",
        canvaLink:"Use canva",
        canvaHint:"Use canva to design a suitable cover",
        avatarFormat: "Cover format",
        fileFormat: "File format",
        historicalInfo: "Historical Information",
        link: "Link",
        bookTitle: "Book Title",
        bookTilePlaceholder: "Book Title (Limit 60 characters)",
        languageTile: "Language",
        audiences: "Primary Audience",
        gender1: "Male",
        gender2: "Female",
        contentAgeGroup: "Content Age Group",
        contentClass: "Category",
        contentClassPlaceholder: "Select a category",
        bookTag: "Book Tags",
        bookTagPlaceholder: "Select tags",
        bookDesc: "Summary",
        bookDescPlaceholder: "Write a description for your book (between 20 and 900 words)",
        createButton: "Create",
        bookLanguages: "Spanish, English",
        picUploadHint1: "Must upload an image in JPG format!",
        picUploadHint2: "Image size should not exceed 5MB!",
        bookAddHint1: "Book title cannot be empty",
        bookAddHint2: "Cover cannot be empty",
        bookAddHint3: "Summary cannot be empty",
        addBookSuccess: "Story added successfully, go and add chapters now",
        success: "success",
        cancel: "cancel",
        penNameNotNull: "Pen name cannot be empty",
        registerSuccess: "login was successful",
        registerFail: "login has failed",
        registerAuthor: "Register as a author",
        register: "Register",
        penName: "Pen name",
        yourPenName: "Your pen name"
    },
    bookList: {
        title: "Novel List",
        title1: "Title",
        title2: "Category",
        title3: "Click Count",
        title4: "Yesterday's Subscriptions",
        title5: "Update Time",
        title6: "Total Words",
        title7: "Action",
        btn: "Publish Novel",
        btn1: "Chapter Management",
        hint1: "Update",
        hint2: "Nothing here - create a book now!",
    },
    chapterAdd: {
        addBtn: "Chapter List",
        search: "Search",
        titlePlaceholder: "Enter a title",
        vipRadio1: "Paid",
        vipRadio2: "Free",
        pricePlaceholder: "Enter chapter price",
        draftFontCount: "words",
        draftBtn: "Save as Draft",
        createBtn: "Create",
        viewBtn: "Preview",
        hint1: "Chapter title cannot be empty!",
        hint2: "Chapter content cannot be empty!",
        hint3: "Chapter content is too short!",
        hint4: "Update successful!",
        hint5: "Publish Drafts"
    },
    chapterPreview:{
        goBack: "Go Back"
    },
    chapterDrafts: {
        link1: "Novel Management",
        link2: "Published Chapters",
        title: "Draft Box",
        title1: "Chapter Name",
        title2: "Update Time",
        title3: "Action",
        hint1: "Update",
        hint2: "Nothing here - create a chapter now!",
        btn1: "Edit",
        btn2: "Delete",
    },
    chapterList: {
        link1: "Novel Management",
        link2: "Draft Box",
        title: "Chapter List",
        title1: "Chapter Name",
        title2: "Update Time",
        title3: "Is Paid",
        title4: "Action",
        hint1: "Update",
        hint2: "Nothing here - create a chapter now!",
        hint3: "Paid",
        hint4: "Free",
        btn: "New Chapter",
        btn1: "Edit",
        btn2: "Delete",
        btn3: "Are you sure to delete this?",
        btn4: "Delete Success！",
    },
    readPage: {
        preChapterBtn: "Previous Chapter",
        newChapterBtn: "Next Chapter",
        chapterAbout: "Related Chapters",
        shareTo: "Share To",
        unLock: "UnLock",
        unlockPrice: "Unlocked immediately"
    },
    feadBack: {
        title: "Feedback",
        questionType: "Select question type",
        questionPlaceholder: "Select question type",
        email: "Email Address",
        emailPlaceholder: "Enter your email address here",
        content: "Please leave your suggestions here",
        contentPlaceholder: "Please leave your suggestions here",
        picTitle: "Upload screenshots to help us better understand your ideas",
        button: "Submit",
        hint1: "Must upload an image in JPG format!",
        hint2: "Image size should not exceed 5MB!",
        hint3: "Email cannot be empty!",
        hint4: "Feedback question cannot be empty!",
        hint5: "Feedback content cannot be empty!",
        hint6: "Feedback content should be at least 10 characters long!",
        hint7: "Submission successful!",
    },
    aboutAuthor: {
        row1: "Be a writer in Minovel!",
        row2: "Dedicated to creating for all the readers in the world who love to read novels,",
        row3: "Welcome to all those who have the dream of writing!",
        row4: "Be a writer",
        row6: "Professional editorial team to provide one-on-one content guidance service for you.",
        row8: "Mature operation and marketing team to promote and publicise your book.",
        row10: "Regularly organise various essay writing activities to stimulate authors' creativity.",
        row19: "The author's voice:",
        row20: "1. Writing tips: as an online novel writer, I'd love to say that we should be grateful to our readers!After writing novels for so many years, I have summarized many writing tips. Perhaps all web novel writers feel that every step they take is their own hard work. And what I would like to say even more is that we, writers, cannot subtract ourselves from the support of readers in every step of our success.",
        row33: "2. In my opinion, there are three broad tiers of writers. Beginner writers just write stories; intermediate writers are able to form a point of view of their own; and advanced writers are able to not only distill something they want to express from a story, but also reflect reality. My creative process is also divided into three stages. The first is the stage of instinctive creation, that is, to create on emotional impulse, full of emotion but not much writing skill. The second stage is the creative bottleneck. In this stage, what I do is to read a lot, learn by heart, practice more, copy the full text of the foundation, to understand the writing skills, so that the work is presented more sophisticated. The third stage is to try to participate in the work of thinking and feeling, so that the text can express something other than the text. For example, to use the spiritual power embedded in the story to infect the readers, so that the readers will be filled with the power to want to go upward. I have deeply realized that creativity should be based on reality, and the foot should be on the whole screen.",
        row23: "Author Circle:",
        row27: "1. Follow social accounts and follow editors to learn more about writing. Get more inspired by other great authors from around the world. Create a good environment for creativity and cultural exchange.",
        row29: "2. Get more rewards while learning, and let writing reach a better life.",
        row31: "3. Promote and publicize your work on social media, so that more people understand and read it, and become your loyal readers.",
    },
}
